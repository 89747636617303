import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";

import CustomModal from "../../custom-modal/custom-modal";
import { CustomButton, CustomTextBox } from "../../../atoms";
import { Colors } from "../../../../configs";
import { IInstruction } from "../../../../typings/interfaces/pos/cart-item";
import { useAppSelector } from "../../../../hooks/reduxHooks";

export interface IInstructionPromptModalProps {
  onClose(): void;
  promptOpen: boolean;
  onSubmit(instruction: IInstruction[]): void;
}

const InstructionPromptModal: FC<IInstructionPromptModalProps> = ({
  promptOpen,
  onClose,
  onSubmit,
}) => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const isAlreadyAdded: boolean =
    selectedCartItem?.instructions.some((i) => i.id === 100000) || false;
  const [instruction, setInstruction] = useState<string>(
    selectedCartItem?.instructions.filter((i) => i.id === 100000)[0]?.label ??
      ""
  );

  const handleAddInstruction = () => {
    onSubmit([
      ...(selectedCartItem?.instructions || []),
      { id: 100000, label: instruction },
    ]);
  };

  const handleRemoveInstruction = () => {
    onSubmit(
      selectedCartItem?.instructions.filter((i) => i.id !== 100000) || []
    );
    onClose();
  };

  const handleUpdateInstruction = () => {
    onSubmit(
      selectedCartItem?.instructions.map((i) =>
        i.id === 100000 ? { id: 100000, label: instruction } : i
      ) || []
    );
  };

  const handleOnSubmit = () => {
    if (isAlreadyAdded) {
      handleUpdateInstruction();
    } else {
      handleAddInstruction();
    }
    onClose();
  };

  return (
    <CustomModal
      mode="popup"
      title=""
      openModal={promptOpen}
      onClose={onClose}
      width="40rem"
      height="25rem"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", py: "0.6rem" }}>
        <Typography sx={{ fontSize: "1.5rem" }}>
          Please provide any specific instructions for this item.
        </Typography>
        <CustomTextBox
          placeholder="Enter Your Instruction"
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            gap: "0.7rem",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "12rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              background: "none",
              border: `0.11429rem solid ${Colors.MAIN_GRAY}`,
              color: Colors.MAIN_GRAY,
              fontWeight: 600,
            }}
            disabled={!isAlreadyAdded}
            onClick={handleRemoveInstruction}
          >
            Remove
          </CustomButton>
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "13.5rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
            disabled={instruction === ""}
            onClick={handleOnSubmit}
          >
            {isAlreadyAdded ? "Update" : "Add"}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default InstructionPromptModal;
