import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponseSingle } from "../../typings/interfaces";
import { IcustomerAvgSpendingResponse } from "../../typings/interfaces/responses";
import { GET_CUSTOMER_AVG_SPENDINGS  } from "../../configs/apiConfig";
import { IcustomerAvgSpendingsRequest } from "../../typings/interfaces/requests/customer-requests";

export const getAllCustomerAvgSpendingsAction = createAsyncThunk(
    "customer/customerAvgSpendings",
    async (
        { customerId, startDate, endDate }: IcustomerAvgSpendingsRequest, { rejectWithValue }) => {
        try {
            const response = await axios.post<BaseResponseSingle<IcustomerAvgSpendingResponse>>(
                GET_CUSTOMER_AVG_SPENDINGS(customerId),
                { customerId, startDate, endDate } 
            );
            return response.data;
        }
        catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
)
