import React from "react";
import {
  Box,
  TextField,
  Typography,
  Tabs,
  Tab,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dayjs } from "dayjs";

import { PosLayout } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import CustomDateTimePicker from "../../../components/atoms/custom-date-time-picker/custom-date-time-picker";
import PintagTable from "./pintag-table";
import { Colors } from "../../../configs";
import { useNavigate } from "react-router-dom";

interface ISearchPintagScreenProps {
  openTab?: number;
}

export const SearchPintagScreen: React.FC<ISearchPintagScreenProps> = ({
  openTab = 0,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(openTab);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const [filterExpand, setFilterExpand] = React.useState(false); // Added filterExpand state
  const [tableContentHeight, setTableContentHeight] = React.useState(50); // Added tableContentHeight state
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabTitles = ["Pintag Search", "Invoice Search", "General Search"];

  return (
    <PosLayout>
      <PosInnerPageLayout title={""}>
        <Box sx={{ padding: "1rem -2rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTab-root": {
                  background: Colors.BACKSHADE_GRAY,
                  color: Colors.PRIMARY,
                  height: "3.07142857143rem",
                  minHeight: "3.07142857143rem",
                  width: "11.9285714286rem",
                  padding: 0,
                  marginTop: "3rem",
                  textTransform: "none",
                },
                "& .Mui-selected": {
                  background: Colors.PRIMARY,
                  color: `${Colors.WHITE} !important`,
                },
                "& .MuiTab-root:first-of-type": {
                  borderRadius: "0.71rem 0 0 0",
                },
                "& .MuiTab-root:nth-of-type(2)": {
                  borderRadius: "0",
                },
                "& .MuiTab-root:nth-of-type(3)": {
                  borderRadius: "0 0.71rem 0 0",
                },
              }}
            >
              <Tab
                label="Pintag Search"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
              <Tab
                label="Invoice Search"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
              <Tab
                label="General Search"
                sx={{ fontWeight: "bold", fontSize: "1.142rem" }}
              />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: Colors.BACKSHADE_GRAY,
            padding: "2rem",
            borderRadius: "0 0.71rem 0.71rem 0.71rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "3.42857142857rem",
              fontWeight: "bold",
              color: Colors.MAIN_GRAY,
              marginTop: "3.2rem",
              flex: 1,
            }}
          >
            {tabTitles[selectedTab]}
          </Typography>

          <Box
            sx={{
              background: Colors.BACKSHADE_GRAY,
              padding: "2rem",
              marginTop: "1rem",
              marginLeft: "-3rem",
              width: "60%",
              flex: 2,
            }}
          >
            {selectedTab === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                <Box sx={{ width: "19.1428571429rem" }}>
                  <Typography
                    variant="body1"
                    sx={{ mb: "0.5rem", fontSize: "1.42857142857rem" }}
                  >
                    Due Date:
                  </Typography>
                  <CustomDateTimePicker
                    type="date"
                    value={dueDate}
                    onChange={(newValue) => setDueDate(newValue)}
                    sx={{
                      width: "19.1428571429rem",
                      height: "3rem",
                      padding: "0",
                      fontSize: "1rem",
                      "& input": {
                        height: "2.4rem",
                        padding: "0.5rem",
                        fontSize: "1.42857142857rem",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "19.1428571429rem" }}>
                  <Typography
                    variant="body1"
                    sx={{ mb: "0.5rem", fontSize: "1.42857142857rem" }}
                  >
                    Rack Location:
                  </Typography>
                  <Select
                    fullWidth
                    size="small"
                    defaultValue=""
                    sx={{ height: "3rem", fontSize: "1.28571428571rem" }}
                  >
                    <MenuItem value="notAssigned" sx={{ fontSize: "1.142rem" }}>
                      Not Assigned
                    </MenuItem>
                    <MenuItem value="inArACK" sx={{ fontSize: "1.142rem" }}>
                      In a rack
                    </MenuItem>
                    <MenuItem value="all" sx={{ fontSize: "1.142rem" }}>
                      All
                    </MenuItem>
                  </Select>
                </Box>

                <Box sx={{ width: "19.1428571429rem" }}>
                  <Typography
                    variant="body1"
                    sx={{ mb: "0.5rem", fontSize: "1.42857142857rem" }}
                  >
                    Sort by:
                  </Typography>
                  <Select
                    fullWidth
                    size="small"
                    defaultValue=""
                    sx={{ height: "3rem", fontSize: "1.28571428571rem" }}
                  >
                    <MenuItem
                      value="latestInvoice"
                      sx={{ fontSize: "1.142rem" }}
                    >
                      Latest Invoice
                    </MenuItem>
                    <MenuItem
                      value="latestPintag"
                      sx={{ fontSize: "1.142rem" }}
                    >
                      Latest Pintag
                    </MenuItem>
                  </Select>
                </Box>

                <Box sx={{ width: "20.2142857143rem", marginTop: "2.6rem" }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Search Invoice"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ fontSize: "1.85714285714rem" }} />
                        </InputAdornment>
                      ),
                      sx: {
                        height: "3rem",
                        "&::placeholder": { fontSize: "1.142rem" },
                      },
                    }}
                    inputProps={{
                      sx: { fontSize: "1.142rem" },
                    }}
                  />
                </Box>

                {/* Table below the form */}
                <Box
                  sx={{
                    width: "135.07rem",
                    height: filterExpand
                      ? `${tableContentHeight + 0.71}rem`
                      : `${tableContentHeight - 5}rem`,
                    background: Colors.BACKSHADE_GRAY,
                    padding: "0.5rem",
                    transition: "all 0.35s",
                    marginTop: "2rem",
                    marginLeft: "-43rem",
                  }}
                >
                  <PintagTable
                    contentHeight={
                      filterExpand
                        ? `${tableContentHeight - 1}rem`
                        : `${tableContentHeight}rem`
                    }
                  />
                </Box>
              </Box>
            )}

            {/* Content for Invoice Search */}
            {selectedTab === 1 && (
              <Typography>Invoice Search Content</Typography>
            )}

            {/* Content for General Search */}
            {selectedTab === 2 && (
              <Typography>General Search Content</Typography>
            )}
          </Box>
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default SearchPintagScreen;
