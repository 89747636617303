import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponseSingle } from "../../typings/interfaces";
import { IcustomerInsightResponse } from "../../typings/interfaces/responses";
import {
  GET_CUSTOMER_INSIGHTS,
  GET_CUSTOMER_INTERACTIONS_BY_DAY,
  GET_CUSTOMER_OUTSTANDING,
} from "../../configs/apiConfig";
import { ICustomerActivitiesByDayResponse } from "../../typings/interfaces/responses/customer-activities-by-day-response";
import { ICustomerOutstandingResponse } from "../../typings/interfaces/responses/customer-outstanding-response";

export const getAllCustomerInsightsAction = createAsyncThunk(
  "customerInsights/all-customerInsights",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<IcustomerInsightResponse>
      >(GET_CUSTOMER_INSIGHTS(id));
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerActivitiesByDayAction = createAsyncThunk(
  "customer/get-interactions",
  async (customerId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<ICustomerActivitiesByDayResponse>
      >(GET_CUSTOMER_INTERACTIONS_BY_DAY(customerId));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomerOutstandingAction = createAsyncThunk(
  "customer/get-outstanding",
  async (customerId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponseSingle<ICustomerOutstandingResponse>
      >(GET_CUSTOMER_OUTSTANDING(customerId));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
