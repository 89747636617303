import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
  FormControl,
  Select,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

import { PosLayout } from "../../../components/layouts/pos-layout/PosLayout";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getUserNotesByIdAction } from "../../../redux/actions/customerAction";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CustomerSliceAction } from "../../../redux/slice/customerSlice";
import { useUserNote } from "../../../providers/UserNoteProvider";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import { CustomButton } from "../../../components/atoms";
import CustomerDetailSection from "../../../components/common/pos/customer-detail-section/customer-detail-section";
import OrderDetailSection from "../../../components/common/pos/order-detail-section/order-detail-section";
import CartSection from "../../../components/common/pos/cart-section/cart-section";
import CartQtyHandlerSection from "../../../components/common/pos/cart-qty-handler-section/cart-qty-handler-section";
import CustomMarquee from "../../../components/common/pos/marquee/marquee";
import CustomerHistoryTable from "../../../components/common/pos/customer-history-table/customer-history-table";
import PickupDetailsSection from "../../../components/common/pos/pickup-details-section/pickup-details-section";
import CustomDateTimePicker from "../../../components/atoms/custom-date-time-picker/custom-date-time-picker";
import SingleDetail, {
  ISingleDetailProps,
} from "../../../components/atoms/single-detail/single-detail";

import { Colors } from "../../../configs";
import { CartSliceAction } from "../../../redux/slice/cartSlice";

export interface ICustomerHistoryPageProps {
  pickupMode?: boolean;
}

const CustomerHistoryPage: FC<ICustomerHistoryPageProps> = ({
  pickupMode = false,
}) => {
  const rightBottomButtons = [
    { label: "Pre Pay - Not Pickup", btnColor: Colors.SECONDARY },
    { label: "Pay and Pickup", btnColor: Colors.PRIMARY },
    { label: "Print Invoice Location", btnColor: Colors.MAIN_GRAY },
    { label: "Add Retail Item", btnColor: Colors.MAIN_GRAY },
    { label: "Partial Pickup", btnColor: Colors.MAIN_GRAY },
    { label: "-", btnColor: Colors.MAIN_GRAY },
  ];

  const details: ISingleDetailProps[] = [
    { label: "Total Invoice", value: 2 },
    { label: "Total Price", value: 5 },
    { label: "Retail Items", value: "$ 0.00" },
    { label: "Total", value: "$ 22.00" },
    { label: "Discounts", value: "$ 0.00" },
    { label: "Taxes", value: "$ 0.00" },
    { label: "Total Due", value: "$ 22.00" },
  ];

  const [filterExpand, setFilterExpand] = useState(pickupMode ? false : true);
  const [sortOption, setSortOption] = useState("invoice" as string);
  const [sortOrder, setSortOrder] = useState("Asc" as string);
  const { showNote } = useUserNote();
  const { cartMode } = useAppSelector((state) => state.cart);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const params = useParams();
  const customerId = params.id;

  const changeCartMode = (mode: "check-in" | "pickup") => {
    if (cartMode !== mode) {
      dispatch(CartSliceAction.setCartMode(mode));
    }
    dispatch(CartSliceAction.clearSession());
  };

  useEffect(() => {
    changeCartMode("pickup");
  }, [cartMode, location.pathname]);

  const [dateIn, setDateIn] = useState<Dayjs | null>(null);
  const [dueDate, setDueDate] = useState<Dayjs | null>(null);
  const [pickupDate, setPickupDate] = useState<Dayjs | null>(null);
  const [payDate, setPayDate] = useState<Dayjs | null>(null);
  const [selectedDateType, setSelectedDateType] = useState("dateIn");

  const handleDateChange = (newValue: Dayjs | null) => {
    switch (selectedDateType) {
      case "dateIn":
        setDateIn(newValue);
        break;
      case "dueDate":
        setDueDate(newValue);
        break;
      case "pickupDate":
        setPickupDate(newValue);
        break;
      case "payDate":
        setPayDate(newValue);
        break;
      default:
        break;
    }
  };

  const [paymentStatus, setPaymentStatus] = useState("");
  const [pickingStatus, setPickingStatus] = useState("");

  const handlePaymentStatusChange = (event: SelectChangeEvent<string>) => {
    setPaymentStatus(event.target.value);
  };

  const handlePickingStatusChange = (event: SelectChangeEvent<string>) => {
    setPickingStatus(event.target.value);
  };

  let tableContentHeight = pickupMode ? 21.92 : 39.07; // to make table height adjustable

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSortOrder(event.target.value);
  };

  useEffect(() => {
    if (customerId) {
      dispatch(getUserNotesByIdAction(customerId as string)).then(
        (res: any) => {
          showNote(NoteOccasion.ON_CHECKOUT, res.payload);
        }
      );
    }
    return () => {
      dispatch(CustomerSliceAction.clearCustomerNotes());
    };
  }, [customerId]);

  useEffect(() => {
    return () => {
      dispatch(CartSliceAction.clearSession());
    };
  }, []);

  return (
    <PosLayout>
      <PosInnerPageLayout
        // onClickBack={() => {
        //   navigate(`/pos/${customerId}`);
        // }}
        title={""}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            gap: "0.57rem",
          }}
        >
          <Box
            sx={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
              gap: "0.57rem",
              height: "100%",
              maxHeight: "100% !important",
            }}
          >
            <CustomerDetailSection
              showAnalysisButton={true}
              showEditButton={true}
              showHistoryButton={true}
            />
            <CartSection onSelect={() => {}} />
            <CustomMarquee text="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore animi magnam provident cupiditate nisi ea nostrum ipsam, nihil accusantium alias dolorum quasi itaque cum qui dolores quo, recusandae nulla et." />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1.07rem",
                flexShrink: 0,
              }}
            >
              <OrderDetailSection />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.71rem",
                }}
              >
                <CartQtyHandlerSection />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "0.71rem",
                  }}
                >
                  <IconButton
                    sx={{
                      background: Colors.MAIN_GRAY,
                      height: "100%",
                      aspectRatio: "1/1",
                      borderRadius: "0.71rem",
                      color: Colors.WHITE,
                      "&:hover": {
                        background: `${Colors.MAIN_GRAY} !important`,
                      },
                      "&:disabled": {
                        background: `${Colors.MAIN_GRAY} !important`,
                        color: Colors.TEXT_GRAY_DARK,
                      },
                    }}
                    disabled={true}
                  >
                    <DeleteIcon sx={{ fontSize: "1.78rem" }} />
                  </IconButton>
                  <CustomButton
                    sx={{
                      background: Colors.MAIN_GRAY,
                      color: Colors.WHITE,
                      fontSize: "1.42rem",
                      flexGrow: 1,
                      borderRadius: "0.71rem",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: `${Colors.MAIN_GRAY} !important`,
                      },
                    }}
                    disabled={true}
                  >
                    Notes
                  </CustomButton>
                  <CustomButton
                    sx={{
                      background: Colors.MAIN_GRAY,
                      color: Colors.WHITE,
                      fontSize: "1.42rem",
                      flexGrow: 1,
                      borderRadius: "0.71rem",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: `${Colors.MAIN_GRAY} !important`,
                      },
                    }}
                    disabled={true}
                  >
                    Customization
                  </CustomButton>
                </Box>
                <CustomButton
                  sx={{
                    width: "100%",
                    fontSize: "2.28rem",
                    borderRadius: "0.71rem",
                    background: Colors.PRIMARY,
                    color: Colors.WHITE,
                    textTransform: "capitalize",
                    flexGrow: 1,
                    "&:hover": {
                      background: `${Colors.PRIMARY} !important`,
                    },
                  }}
                >
                  Pickup
                </CustomButton>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "0.14rem", background: Colors.ACCENT_GRAY }}></Box>
          <Box
            sx={{
              flex: 5,
              display: "flex",
              flexDirection: "column",
              gap: "1.42rem",
            }}
          >
            <Typography sx={{ fontSize: "2.57rem", color: "#4D4D4D" }}>
              {pickupMode ? "Pickup" : "Customer History"}
            </Typography>
            {pickupMode ? null : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></Box>
            )}

            {!pickupMode && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.42rem",
                  overflow: "hidden !important",
                  height: filterExpand ? "8.07142857143rem" : "0rem",
                  marginBottom: filterExpand ? "0rem" : "-1.42rem",
                  transition: "all 0.35s",
                  background: Colors.BACKGROUND_GRAY,
                  width: "73.071rem",
                  padding: "1.32rem",
                  borderRadius: "0.78rem",
                  justifyContent: "space-between",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0,
                      alignItems: "flex-start",
                    }}
                  >
                    {/* Buttons Section */}
                    <Box
                      sx={{
                        // width: "24.3571428571rem",
                        width: "100%",
                        display: "flex",
                        marginBottom: 0,
                        height: "2.21428571429rem",
                      }}
                    >
                      {["dateIn", "dueDate", "pickupDate", "payDate"].map(
                        (dateType, index) => (
                          <Button
                            key={dateType}
                            variant="outlined"
                            onClick={() => {
                              setSelectedDateType(dateType);
                            }}
                            sx={{
                              flex: 1,
                              mx: 0,
                              whiteSpace: "nowrap",
                              bgcolor:
                                selectedDateType === dateType
                                  ? "secondary.main"
                                  : "transparent",
                              color:
                                selectedDateType === dateType
                                  ? "white"
                                  : Colors.SECONDARY,
                              borderColor: Colors.SECONDARY,
                              borderRadius:
                                index === 0
                                  ? "0.28571428571rem 0 0 0"
                                  : index === 3
                                  ? "0 0.28571428571rem 0 0"
                                  : 0,
                              fontFamily: "Sebino-Medium",
                              fontSize: "1.14285714286rem",
                              textTransform: "none",
                              "&:hover": {
                                bgcolor:
                                  selectedDateType === dateType
                                    ? "secondary.main"
                                    : "transparent",
                                color:
                                  selectedDateType === dateType
                                    ? "white"
                                    : Colors.SECONDARY,
                              },
                            }}
                          >
                            {dateType.charAt(0).toUpperCase() +
                              dateType.slice(1).replace(/([A-Z])/g, " $1")}
                          </Button>
                        )
                      )}
                    </Box>

                    <Box
                      sx={{
                        // width: "26.7571428571rem",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "0 0 0.28571428571rem 0.28571428571rem",
                        mt: 0,
                        border: "0.07142857142rem solid gray",
                        height: "2.21428571429rem",
                        bgcolor: "WHITE",
                      }}
                    >
                      <CustomDateTimePicker
                        type="date"
                        value={
                          selectedDateType === "dateIn"
                            ? dateIn
                            : selectedDateType === "dueDate"
                            ? dueDate
                            : selectedDateType === "pickupDate"
                            ? pickupDate
                            : payDate
                        }
                        onChange={handleDateChange}
                        disabled={false}
                        sx={{
                          borderRadius: "0 0 0.28571428571rem 0.28571428571rem",
                          // width: "24.3571428571rem",
                          width: "100%",
                          "& .MuiInputBase-root": {
                            border: "none",
                            padding: 0,
                            "&:focus": {
                              boxShadow: "none",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </LocalizationProvider>

                {/* Right Section: Form fields */}
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "11.2857142857rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Payment Status:
                    </Typography>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={paymentStatus}
                        onChange={handlePaymentStatusChange}
                        inputProps={{ "aria-label": "Payment Status" }}
                        sx={{
                          height: "2.21428571429rem",
                          bgcolor: "WHITE",
                        }}
                      >
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="Not Paid">Not Paid</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "11.2857142857rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Picking Status:
                    </Typography>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={pickingStatus}
                        onChange={handlePickingStatusChange}
                        inputProps={{ "aria-label": "Picking Status" }}
                        sx={{ height: "2.21428571429rem", bgcolor: "WHITE" }}
                      >
                        <MenuItem value="Picked">Picked</MenuItem>
                        <MenuItem value="Not Picked">Not Picked</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "11.2857142857rem" }}
                  >
                    <Typography variant="body1" sx={{ mb: "0.5rem" }}>
                      Invoice No:
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        sx: { height: "2.21428571429rem", bgcolor: "WHITE" },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                width: "73.07rem",
                height: filterExpand
                  ? `${tableContentHeight + 6.71}rem`
                  : `${tableContentHeight + 17.71}rem`,
                background: Colors.BACKGROUND_GRAY,
                padding: "1.32rem 0.75rem",
                borderRadius: "0.78rem",
                transition: "all 0.35s",
              }}
            >
              <CustomerHistoryTable
                contentHeight={
                  filterExpand
                    ? `${tableContentHeight}rem`
                    : `${tableContentHeight + 11}rem`
                }
              />
            </Box>

            {/* bottom section for customer history */}
            {!pickupMode && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.64285714rem",
                  height: "11.8571429rem",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    background: Colors.BACKGROUND_GRAY,
                    width: "43.4285714286rem",
                    height: "5.85714285714rem",
                    border: "1px solid black",
                    marginTop: "0.6rem",
                  }}
                >
                  <PickupDetailsSection
                    totalInvoices={3}
                    totalPrice={55.0}
                    totalDiscounts={50.0}
                    totalDue={500.0}
                  />
                </Box>
                <CustomButton
                  sx={{
                    width: "28.3571428571rem",
                    height: "5.85714285714rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    color: Colors.WHITE,
                    fontSize: "2.28rem",
                    background: Colors.PRIMARY,
                    textTransform: "capitalize",
                    alignSelf: "center",
                    marginLeft: "auto",
                    marginTop: "-5rem",
                  }}
                >
                  Print Rack Loacation
                </CustomButton>
              </Box>
            )}

            {/* bottom serction for pickup */}
            {pickupMode && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.42857143rem",
                }}
              >
                {/* Left Section - Details */}
                <Box
                  sx={{
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    background: Colors.BACKGROUND_GRAY,
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.857rem",
                    width: "25.5rem",
                  }}
                >
                  {details.map((detail, index) => (
                    <SingleDetail
                      key={index}
                      label={detail.label}
                      value={detail.value}
                      color={Colors.PRIMARY}
                    />
                  ))}
                </Box>

                {/* Right Section - Buttons */}
                <Box
                  sx={{
                    width: "44.07142857142857rem",
                    borderRadius: "0.71rem",
                    padding: "1.9rem",
                    paddingTop: "3.8rem",
                    background: Colors.BACKGROUND_GRAY,
                    flex: 9,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  {rightBottomButtons.map((rightBottomButton, index) => (
                    <CustomButton
                      key={index}
                      sx={{
                        width: "20.56857142857143rem",
                        height: "4.686428571428571rem",
                        borderRadius: "0.71rem",
                        padding: "0.64285714rem",
                        color: Colors.WHITE,
                        fontSize: "1.71428571rem",
                        background: rightBottomButton.btnColor,
                        textTransform: "capitalize",
                      }}
                    >
                      {rightBottomButton.label}
                    </CustomButton>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default CustomerHistoryPage;
