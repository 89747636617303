import { Box, Typography, SxProps, Theme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { PinTagButton } from "../../../atoms/pintag-button/pintag-button";
import { CustomTextBox } from "../../../atoms";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import {
  getAllColorPinTagsAction,
  getAllServicePinTagsAction,
} from "../../../../redux/actions/pintagAction";
import { IColorPintag } from "../../../../typings/interfaces/pos/pintag";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import { cloneDeep } from "lodash";
import { addSession } from "../../../../redux/actions/cartAction";
import { pxToRem } from "../../../../util";

export interface IPinTagsSectionProps {
  sx?: SxProps<Theme>;
  itemsCount: number;
  serviceTypeId: number;
  sessionId: string;
  isPintagModalSection?: boolean;
  setIsCompleted?: (isCompleted: boolean) => void;
  onChange?: (
    color: string | null,
    tags: string[],
    textBoxCount: number
  ) => void;
}

export const PinTagsSection: FC<IPinTagsSectionProps> = ({
  sx = {},
  itemsCount,
  serviceTypeId,
  sessionId,
  isPintagModalSection = false,
  setIsCompleted,
  onChange,
}) => {
  const [sortedPinTags, setSortedPinTags] = useState<IColorPintag[]>([]);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [textBoxCount, setTextBoxCount] = useState<number>(1);
  const {
    getColorPintagsError,
    getServicePintagsError,
    getColorPintags,
    getServicePintags,
  } = useAppSelector((state) => state.pintag);
  // const [sessionTagsLength, setSessionTagsLength] = useState<number>(0);

  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const { drafts, cart, selectedSession, selectedServiceType } = useAppSelector(
    (state) => state.cart
  );
  const [serviceTagCount, setServiceTagCount] = useState<number>(1);

  const handleAssignPinTag = (color: string | null, tags: string[]) => {
    const targetTagList = tags.slice(0, textBoxCount);
    let isIncompleteTags =
      targetTagList.filter((tag) => tag.length !== 3).length > 0;

    // alert(`${color} ${targetTagList.join(", ")} ${isIncompleteTags}`);

    if (
      color === null ||
      tags.length === 0 ||
      targetTagList.includes("") ||
      isIncompleteTags
    ) {
      setIsCompleted && setIsCompleted(false);
    } else {
      setIsCompleted && setIsCompleted(true);

      if (isPintagModalSection) {
        onChange && onChange(color, targetTagList, textBoxCount);
      } else {
        let newSessionClone = cloneDeep(
          cart.find((session) => session.sessionId === sessionId)
        );
        if (newSessionClone) {
          newSessionClone.pinTag = {
            color: color,
            tags: tags,
          };
          dispatch(addSession(newSessionClone));
        }
      }
    }
  };

  useEffect(() => {
    if ((getColorPintags?.body?.length ?? 0) > 0) {
      const sortedPinTags = [...(getColorPintags?.body ?? [])].sort(
        (a, b) => a.orderIndex - b.orderIndex
      );
      setSortedPinTags(sortedPinTags);
    }
  }, [getColorPintags]);
  useEffect(() => {
    if ((getServicePintags?.body?.length ?? 0) > 0) {
      const servicePintag = getServicePintags?.body.find(
        (servicePintag) => servicePintag.serviceTypeId === serviceTypeId
      );
      setServiceTagCount(servicePintag?.tagCount ?? 1);
    }
  }, [getServicePintags]);
  useEffect(() => {
    getColorPintagsError &&
      dispatch(getAllColorPinTagsAction()).catch((error) => {
        snackbar.showSnackbar(error.message, "error");
      });
    getServicePintagsError &&
      dispatch(getAllServicePinTagsAction()).catch((error) => {
        snackbar.showSnackbar(error.message, "error");
      });
  }, [getServicePintagsError, getColorPintagsError]);
  useEffect(() => {
    const session = cart.find((session) => session.sessionId === sessionId);
    const selectedTagsLength = Math.ceil(
      (session?.cartItems.length ?? 1) / serviceTagCount
    );

    if (session) {
      setSelectedColor(session.pinTag?.color ?? null);
      selectedTagsLength !== 9 &&
        setSelectedTags(
          session.pinTag?.tags.concat(
            Array.from({ length: 9 - selectedTagsLength }).map(() => "")
          )
        );
      setTextBoxCount(selectedTagsLength);
    }
  }, [cart, serviceTagCount]);

  useEffect(() => {
    dispatch(getAllColorPinTagsAction());
    dispatch(getAllServicePinTagsAction());
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isPintagModalSection ? pxToRem(25) : pxToRem(28),
        background: isPintagModalSection ? "none" : Colors.BACKSHADE_GRAY,
        borderRadius: pxToRem(8),
        padding: isPintagModalSection
          ? "0"
          : `${pxToRem(43.305)} ${pxToRem(42.55)}`,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: pxToRem(8),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: Colors.ACCENT_GRAY,
          borderRadius: pxToRem(4),
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          borderRadius: pxToRem(4),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: isPintagModalSection ? pxToRem(18.79) : pxToRem(11),
        }}
      >
        <Typography
          sx={{
            width: isPintagModalSection ? pxToRem(174.66) : pxToRem(158),
            fontSize: "1.5rem",
            fontWeight: "bold",
            flexShrink: 0,
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Color Code
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: isPintagModalSection ? pxToRem(14.13) : pxToRem(10.33),
            rowGap: pxToRem(10.61),
            flexWrap: "wrap",
            flexGrow: 1,
          }}
        >
          {sortedPinTags.map((pinTag) => {
            return (
              <PinTagButton
                key={pinTag.id}
                color={pinTag.color}
                code={pinTag.alias}
                isSelected={selectedColor === pinTag.alias}
                onClick={() => {
                  setSelectedColor(pinTag.alias);
                  handleAssignPinTag(pinTag.alias, selectedTags);
                }}
                isPintagModalButton={isPintagModalSection}
              />
            );
          })}
          {Array.from({
            length: (isPintagModalSection ? 12 : 9) - sortedPinTags.length,
          }).map((_, index) => {
            return (
              <PinTagButton
                key={index}
                color={Colors.ACCENT_GRAY}
                code={"N/A"}
                isSelected={false}
                onClick={() => {}}
                disabled={true}
                isPintagModalButton={isPintagModalSection}
              />
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: isPintagModalSection ? pxToRem(18.79) : pxToRem(11),
        }}
      >
        <Typography
          sx={{
            width: isPintagModalSection ? pxToRem(174.66) : pxToRem(158),
            fontSize: "1.5rem",
            fontWeight: "bold",
            flexShrink: 0,
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Tag Number(s)
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: pxToRem(8),
            rowGap: pxToRem(11),
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {Array.from({ length: 9 }).map((_, index) => {
            return (
              <CustomTextBox
                disabled={!(textBoxCount >= index + 1)}
                sx={{
                  width: isPintagModalSection ? pxToRem(91.16) : pxToRem(84.67),
                  maxHeight: pxToRem(30),
                  display:
                    !isPintagModalSection && !(textBoxCount >= index + 1)
                      ? "none"
                      : "inline-flex",
                  "& input": {
                    backgroundColor: !(textBoxCount >= index + 1)
                      ? `${Colors.ACCENT_GRAY} !important`
                      : Colors.WHITE,
                    borderRadius: pxToRem(7),
                    maxHeight: pxToRem(30),
                    height: pxToRem(30),
                    padding: `${pxToRem(5.5)} ${
                      isPintagModalSection ? pxToRem(31.71) : pxToRem(28)
                    }`,
                    fontSize: pxToRem(16),
                    lineHeight: pxToRem(19.2),
                    boxSizing: "border-box",
                  },
                  // borderRadius: pxToRem(7),
                  "& fieldset": {
                    border: !(textBoxCount >= index + 1)
                      ? `none !important`
                      : `${pxToRem(2)} solid ${
                          selectedTags[index]
                            ? selectedTags[index].length === 3
                              ? Colors.PRIMARY
                              : Colors.ERROR_RED
                            : Colors.ACCENT_GRAY
                        } !important`,
                    borderRadius: pxToRem(7),
                    height: pxToRem(35.5),
                  },
                  // height: pxToRem(30),
                  // "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  //   {
                  //     "-webkit-appearance": "none !important",
                  //     margin: 0,
                  //   },
                  // "& input[type=number]": {
                  //   MozAppearance: "textfield !important",
                  // },
                }}
                type="number"
                value={selectedTags[index] ?? ""}
                inputProps={{
                  maxLength: 3, // Max length is not working. so we have to handle it in onChange
                  max: 999,
                  min: 1,
                }}
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value = e.target.value;
                  if (value.length > 3) {
                    value = value.slice(0, 3);
                    return;
                  }
                  const newSelectedTags = [...selectedTags];
                  newSelectedTags[index] = value;
                  setSelectedTags(newSelectedTags);
                  handleAssignPinTag(selectedColor, newSelectedTags);
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: isPintagModalSection ? pxToRem(18.79) : pxToRem(11),
        }}
      >
        <Typography
          sx={{
            width: isPintagModalSection ? pxToRem(174.66) : pxToRem(158),
            fontSize: "1.5rem",
            fontWeight: "bold",
            flexShrink: 0,
            color: Colors.TEXT_GRAY_DARK,
          }}
        >
          Item Count
        </Typography>
        <CustomTextBox
          type="number"
          value={itemsCount}
          disabled
          sx={{
            width: isPintagModalSection ? pxToRem(91.16) : pxToRem(84.67),
            height: pxToRem(30),
            maxHeight: pxToRem(30),
            borderRadius: pxToRem(7),
            "& input": {
              backgroundColor: `${Colors.ACCENT_GRAY_DISABLED} !important`,
              borderRadius: pxToRem(7),
              boxSizing: "border-box",
              padding: `${pxToRem(8)} ${pxToRem(35)}`,
              textAlign: "center",
              fontSize: pxToRem(16),
              lineHeight: pxToRem(19.2),
              height: pxToRem(30),
              maxHeight: pxToRem(30),
            },
            "& fieldset": {
              border: "none",
              borderRadius: pxToRem(7),
            },
          }}
        />
      </Box>
    </Box>
  );
};
