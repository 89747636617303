import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";

import { Colors } from "../../../configs";

const columns = ["Item", "Basic Price ($)"];
const rows = [
  { id: 1, column1: "Row 1", column2: "Row 1" },
  { id: 2, column1: "Row 2", column2: "Row 2" },
  { id: 3, column1: "Row 3", column2: "Row 3" },
  { id: 4, column1: "Row 1", column2: "Row 1" },
  { id: 5, column1: "Row 2", column2: "Row 2" },
  { id: 6, column1: "Row 3", column2: "Row 3" },
  { id: 7, column1: "Row 1", column2: "Row 1" },
  { id: 8, column1: "Row 2", column2: "Row 2" },
  { id: 9, column1: "Row 3", column2: "Row 3" },
];

interface IPriceListTableProps {
  service: number;
}

export const PriceListTable: React.FC<IPriceListTableProps> = ({ service }) => {
  return (
    <Box
      sx={{
        marginTop: "1rem",
        maxHeight: "38.6rem",
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": { width: "0.5rem" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ADADAD",
          borderRadius: "0rem",
          marginRight: "2rem",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          borderRadius: "0rem",
          margin: "2rem 2rem",
        },
      }}
    >
      <TableContainer
        component={Paper}
        sx={{ padding: "0.5rem", backgroundColor: Colors.BACKSHADE_GRAY }}
      >
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0.4rem 0.4rem",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: Colors.ACCENT_GRAY_DISABLED,
                    fontWeight: "bold",
                    width: index === 0 ? "60%" : "40%",
                    ...(index === 0 && { borderTopLeftRadius: "0.71rem" }),
                    ...(index === columns.length - 1 && {
                      borderTopRightRadius: "0.71rem",
                    }),
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(service === 0 ? rows : []).map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  "& > *": {
                    padding: "1rem",
                    borderRight: "0.3rem solid transparent",
                  },
                  "&:last-child > :first-of-type": {
                    borderBottomLeftRadius: "0.71rem",
                  },
                  "&:last-child > :last-of-type": {
                    borderBottomRightRadius: "0.71rem",
                  },
                }}
              >
                <TableCell
                  sx={{
                    backgroundColor: Colors.ACCENT_GRAY,
                    color: Colors.MAIN_GRAY,
                  }}
                >
                  {row.column1}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: Colors.ACCENT_GRAY,
                    color: Colors.MAIN_GRAY,
                  }}
                >
                  {row.column2}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
