import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { OnboardingLayout } from "../../../components/layouts/onboarding-layout/OnboardingLayout";
import { CustomButton } from "../../../components";
import { Colors } from "../../../configs/colors";

import onboardingImg from "../../../assets/images/onboardingImg.svg";

const validationSchema = yup.object().shape({
  store: yup.string().required("Store selection is required"),
  deviceName: yup.string().required("Device name is required"),
});

export const OnboardingPage: React.FC = () => {
  const [selectedDevice, setSelectedDevice] = useState();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleDeviceChange = (event: any) => {
    setSelectedDevice(event.target.value);
  };

  const onSubmit = (data: any) => {
    console.log("Form Submitted: ", data);
    setIsFormSubmitted(true);
  };

  return (
    <OnboardingLayout>
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
        }}
        direction="row"
        wrap="nowrap"
      >
        <Grid
          item
          sx={{
            width: "55.85714285714286rem",
            backgroundColor: Colors.BACKSHADE_GRAY,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={onboardingImg}
            alt="Onboarding Illustration"
            sx={{
              height: "35.71428571428571rem",
              width: "35.71428571428571rem",
            }}
          />
        </Grid>

        <Grid
          item
          sx={{
            flex: 1,
            padding: "2rem 4rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "52.35714285714286rem",
              marginLeft: "10.5rem",
              marginTop: "8rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "0.1rem",
                  backgroundColor: Colors.PRIMARY,
                  width: "100%",
                  position: "absolute",
                  zIndex: 0,
                }}
              />

              <Box
                sx={{
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    backgroundColor: Colors.WHITE,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: Colors.PRIMARY,
                      fontSize: "3rem",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    backgroundColor: Colors.WHITE,
                  }}
                >
                  {isFormSubmitted ? (
                    <CheckCircleIcon
                      sx={{
                        color: Colors.PRIMARY,
                        fontSize: "3rem",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "2.6rem",
                        width: "2.6rem",
                        borderRadius: "50%",
                        backgroundColor: Colors.WHITE,
                        color: Colors.PRIMARY,
                        fontSize: "1rem",
                        border: "0.1rem solid",
                        borderColor: Colors.PRIMARY,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        position: "relative",
                      }}
                    >
                      2
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    height: "2.6rem",
                    width: "2.6rem",
                    borderRadius: "50%",
                    backgroundColor: Colors.WHITE,
                    color: Colors.PRIMARY,
                    fontSize: "1rem",
                    border: "0.1rem solid",
                    borderColor: Colors.PRIMARY,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  3
                </Box>
              </Box>
            </Box>
          </Box>

          <Grid
            item
            sx={{
              flex: 1,
              padding: "2rem 4rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "52.35714285714286rem",
                height: "36.92857142857143rem",
                border: `0.0714285714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                borderRadius: "0.7142857142857143rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5rem",
                marginBottom: "4rem",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2.285714285714286rem",
                    color: Colors.MAIN_GRAY,
                    textAlign: "center",
                    marginLeft: "10rem",
                    marginTop: "3rem",
                    marginBottom: "2rem",
                  }}
                >
                  Presto Valet
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.6rem",
                    marginTop: "1rem",
                  }}
                >
                  {/* Store Selection */}
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.428571428571429rem",
                        color: Colors.MAIN_GRAY,
                        width: "10.5rem",
                      }}
                    >
                      Store
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "28.857rem",
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "28.85714285714286rem",
                          height: "3.428571428571429rem",
                          "& .MuiOutlinedInput-root": {
                            height: "3.428571428571429rem",
                          },
                        }}
                      >
                        <Controller
                          name="store"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              displayEmpty
                              sx={{
                                width: "28.85714285714286rem",
                                height: "3.428571428571429rem",
                              }}
                            >
                              <MenuItem value="Store 1">Store 1</MenuItem>
                              <MenuItem value="Store 2">Store 2</MenuItem>
                              <MenuItem value="Store 3">Store 3</MenuItem>
                            </Select>
                          )}
                        />
                        {errors.store && (
                          <Typography color="error">
                            {errors.store.message}
                          </Typography>
                        )}
                      </FormControl>
                    </Box>
                  </Box>

                  {/* Device Name Input */}
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.428571428571429rem",
                        color: Colors.MAIN_GRAY,
                        width: "10.5rem",
                      }}
                    >
                      Device Name
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "28.857rem",
                      }}
                    >
                      <Controller
                        name="deviceName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            fullWidth
                            sx={{
                              width: "28.85714285714286rem",
                              height: "3.428571428571429rem",
                              "& .MuiOutlinedInput-root": {
                                height: "3.428571428571429rem",
                              },
                            }}
                          />
                        )}
                      />
                      {errors.deviceName && (
                        <Typography color="error" sx={{ marginTop: "0.5rem" }}>
                          {errors.deviceName.message}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* Submit Button */}
                  <CustomButton
                    type="submit"
                    sx={{
                      backgroundColor: Colors.PRIMARY,
                      marginLeft: "11.7rem",
                      marginTop: "2rem",
                      fontSize: "1.714285714285714rem",
                      width: "28.85714285714286rem",
                      height: "4.571428571428571rem",
                    }}
                  >
                    Request Access
                  </CustomButton>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "52.35714285714286rem",
                height: "6.071428571428571rem",
                border: `0.0714285714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
                borderRadius: "0.7142857142857143rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "1rem",
                fontSize: "0.8571428571428571rem",
                color: Colors.MAIN_GRAY,
                paddingLeft: "3rem",
                paddingRight: "3rem",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </OnboardingLayout>
  );
};
