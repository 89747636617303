import { Print, ViewInAr } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { Colors } from "../../../configs/colors";
import PosSettingsLayout from "../../../components/layouts/pos-layout/pos-settings-layout";

const tabs = [
  {
    id: "printer_setup",
    text: "Printer Setup",
    icon: <Print />,
    content: (
      <>
        <Typography
          variant="h5"
          sx={{
            color: Colors.TEXT_GRAY_DARK,
            fontSize: "3.428571428571429rem !important",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Printer Setup
        </Typography>

        <Box
          sx={{
            bgcolor: Colors.BACKGROUND_GRAY,
            borderRadius: "0.71rem",
            width: "47.85714285714286rem",
            height: "10.71428571428571rem",
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            mmarginBottom: "4rem",
            marginTop: "3rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: 1,
              paddingLeft: "2rem",
            }}
          >
            <Typography
              sx={{
                color: Colors.TEXT_GRAY_DARK,
                marginRight: "2rem",
                fontWeight: "bold",
              }}
            >
              Device
            </Typography>
            <Select
              defaultValue="device1"
              sx={{
                width: "27.5rem",
                height: "2.164285714285714rem",
                marginLeft: "7.8rem",
                bgcolor: Colors.WHITE,
                "& .MuiSelect-icon": {
                  color: Colors.WHITE,
                  bgcolor: Colors.SECONDARY,
                  border: `0.1428571428571429rem solid ${Colors.SECONDARY}`,
                  borderRadius: "0.41rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: Colors.WHITE,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: Colors.SECONDARY,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: Colors.SECONDARY,
                },
                "& .MuiSelect-select": {
                  textAlign: "center",
                },
              }}
            >
              <MenuItem value="device1">Device 1</MenuItem>
              <MenuItem value="device2">Device 2</MenuItem>
              <MenuItem value="device3">Device 3</MenuItem>
              <MenuItem value="device4">Device 4</MenuItem>
              <MenuItem value="device5">Device 5</MenuItem>
            </Select>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: "2rem",
            }}
          >
            <Typography
              sx={{
                color: Colors.TEXT_GRAY_DARK,
                marginRight: "2rem",
                fontWeight: "bold",
              }}
            >
              Letter Spacing
            </Typography>
            <TextField
              type="number"
              sx={{
                width: "27.5rem",
                height: "2.164285714285714rem",
                marginLeft: "4rem",
                bgcolor: Colors.WHITE,
                borderRadius: "0.71rem",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: Colors.WHITE,
                  },
                  "&:hover fieldset": {
                    borderColor: Colors.SECONDARY,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: Colors.SECONDARY,
                  },
                  height: "100%",
                },
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30rem",
            marginLeft: "20.5rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: Colors.TEXT_GRAY_DARK,
              borderColor: Colors.TEXT_GRAY_DARK,
              textTransform: "none",
              marginRight: "2rem",
              fontSize: "1.714285714285714rem",
              width: "12.64142857142857rem",
              height: "4.142857142857143rem",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: Colors.PRIMARY,
              color: Colors.WHITE,
              textTransform: "none",
              fontSize: "1.714285714285714rem",
              width: "12.64142857142857rem",
              height: "4.142857142857143rem",
            }}
          >
            Save
          </Button>
        </Box>
      </>
    ),
  },
  {
    id: "racks",
    text: "Racks",
    icon: <ViewInAr />,
    content: (
      <Typography
        variant="h5"
        sx={{
          color: Colors.TEXT_GRAY_DARK,
          fontSize: "3.428571428571429rem !important",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        Racks
      </Typography>
    ),
  },
  {
    id: "developer_tools",
    text: "Developer Tools",
    icon: <ViewInAr />,
    content: (
      <Typography
        variant="h5"
        sx={{
          color: Colors.TEXT_GRAY_DARK,
          fontSize: "3.428571428571429rem !important",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        Developer Tools
      </Typography>
    ),
  },
];

export const SettingsPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  return (
    <PosSettingsLayout
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {tabs.find((tab) => tab.id === activeTab)?.content}
    </PosSettingsLayout>
  );
};
