import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CustomButton, PosLayout } from "../../../components";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import { PriceListTable } from "./price-list-table";
import { Colors } from "../../../configs";

export const PriceList: React.FC = () => {
  const navigate = useNavigate();

  const buttonData = [
    {
      id: "category1",
      text: "Dry Cleaning",
      content: <PriceListTable service={0} />,
    },
    {
      id: "category2",
      text: "Laundry",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category3",
      text: "Household",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category4",
      text: "Alteration",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category5",
      text: "Repairs",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category6",
      text: "Fur Cleaning",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category7",
      text: "Leather",
      content: <PriceListTable service={1} />,
    },
    { id: "category8", text: "Suede", content: <PriceListTable service={1} /> },
    {
      id: "category9",
      text: "Shoe Repair",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category10",
      text: "Rug Cleaning",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category11",
      text: "Costumes",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category12",
      text: "Storage",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category13",
      text: "Uggs & Athletic Shoes",
      content: <PriceListTable service={1} />,
    },
    {
      id: "category14",
      text: "Wedding Growns Clean & Preserve",
      content: <PriceListTable service={1} />,
    },
  ];

  const [selectedButtonId, setSelectedButtonId] = useState(buttonData[0].id);

  return (
    <PosLayout>
      <PosInnerPageLayout title="Basic Pricing">
        <Box
          sx={{
            display: "flex",
            gap: "4rem",
            backgroundColor: Colors.WHITE,
            padding: "2rem",
            borderRadius: "0.7142857142857143rem",
            margin: "0 auto",
            maxWidth: "130rem",
          }}
        >
          <Box
            sx={{
              width: "57rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "2.285714285714286rem",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Select Category
            </Typography>
            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                padding: "1rem",
                borderRadius: "0.71rem",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "0.4rem",
                width: "59rem",
                minHeight: "54.07142857142857rem",
              }}
            >
              {buttonData.map((button) => (
                <CustomButton
                  key={button.id}
                  variant="contained"
                  sx={{
                    width: "calc(99% - 0.3rem)",
                    height: "6.428571428571429rem",
                    boxShadow: "none",
                    borderRadius: "0.71rem",
                    fontSize: "1.857142857142857rem",
                    textTransform: "capitalize",
                    lineHeight: "2.34357143rem",
                    marginLeft: "0.4rem",
                    backgroundColor:
                      selectedButtonId === button.id
                        ? Colors.PRIMARY
                        : Colors.SECONDARY,
                    color: Colors.WHITE,
                    "&:hover": {
                      backgroundColor:
                        selectedButtonId === button.id
                          ? Colors.PRIMARY
                          : Colors.SECONDARY,
                    },
                  }}
                  onClick={() => setSelectedButtonId(button.id)}
                >
                  {button.text}
                </CustomButton>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              width: "57rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: Colors.MAIN_GRAY,
                fontSize: "2.285714285714286rem",
                fontWeight: "bold",
                marginTop: "0.4rem",
              }}
            >
              Item and Basic Price
            </Typography>
            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                padding: "1rem",
                borderRadius: "0.7142857142857143rem",
                height: "43.12857142857143rem",
                width: "57rem",
              }}
            >
              <Typography variant="body1">
                {
                  buttonData.find((button) => button.id === selectedButtonId)
                    ?.content
                }
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: Colors.BACKSHADE_GRAY,
                padding: "1rem",
                borderRadius: "0.7142857142857143rem",
                height: "10.07142857142857rem",
                width: "57rem",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: Colors.MAIN_GRAY,
                  fontSize: "1.714285714285714rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  justifyContent: "center",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                }}
              >
                All above pricing are base prices and additional charge may be
                added for proper care of each garment depending on fabric,
                cleaning & pressing method and handling procedures.
              </Typography>
            </Box>
          </Box>
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default PriceList;
