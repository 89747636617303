import React, { ReactNode, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Print, ViewInAr } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { PosLayout } from "../..";
import { PosInnerPageLayout } from "./PosInnerPageLayout";
import { Colors } from "../../../configs/colors";

interface IPosSettingsLayoutProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabs: { id: string; text: string; icon: JSX.Element }[];
  children: ReactNode;
}

const PosSettingsLayout: React.FC<IPosSettingsLayoutProps> = ({
  activeTab,
  setActiveTab,
  children,
  tabs,
}) => {
  const navigate = useNavigate();

  return (
    <PosLayout>
      <PosInnerPageLayout title="PRESTO VALET">
        <Typography
          variant="h6"
          sx={{
            color: Colors.TEXT_GRAY_DARK,
            fontSize: "1.714285714285714rem !important",
            marginBottom: "2rem",
          }}
        >
          Settings
        </Typography>

        <Box
          sx={{
            display: "flex",
            height: "100%",
            bgcolor: Colors.WHITE,
            padding: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "25rem",
              height: "60rem",
              overflowY: "auto",
              pr: "0.5rem",
              marginRight: "2rem",
              "&::-webkit-scrollbar": { width: "0.4rem" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: Colors.ACCENT_GRAY_DISABLED,
                borderRadius: "0.5rem",
              },
            }}
          >
            {tabs.map((button) => (
              <Button
                key={button.id}
                variant="outlined"
                startIcon={button.icon}
                sx={{
                  mb: 2,
                  color: Colors.MAIN_GRAY,
                  borderColor: Colors.PRIMARY,
                  width: "20rem",
                  height: "4.214285714285714rem",
                  textTransform: "none",
                  fontSize: "1.714285714285714rem",
                  justifyContent: "flex-start",
                  gap: 1,
                  backgroundColor:
                    activeTab === button.id
                      ? Colors.NOTIFIC_GREEN
                      : "transparent",
                  "&:hover": {
                    backgroundColor: Colors.NOTIFIC_GREEN,
                  },
                }}
                onClick={() => setActiveTab(button.id)}
              >
                {button.text}
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              width: "0.2rem",
              bgcolor: "#BCB0B0",
              height: "100%",
              marginRight: "2rem",
            }}
          />
          <Box
            sx={{
              flex: 1,
              bgcolor: Colors.WHITE,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingLeft: "28rem",
            }}
          >
            {children}
          </Box>
        </Box>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default PosSettingsLayout;
