import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Chrono } from "react-chrono";

import { Colors } from "../../../configs";
import CustomModal from "../../common/custom-modal/custom-modal";
import { pxToRem } from "../../../util";

interface InvoiceHistoryModalProps {
  open: boolean;
  onClose: () => void;
  billId: string;
}

const InvoiceHistoryModal: React.FC<InvoiceHistoryModalProps> = ({
  open,
  onClose,
  billId,
}) => {
  const [clickedRow, setClickedRow] = useState<any>(null);

  useEffect(() => {
    if (billId) {
      setClickedRow({
        billId: billId,
        id: Number(billId),
        history: [
          {
            date: "09/12/2022",
            status: "Created by Anns Marie",
            cashier: "Cashier 01",
            details: "545 pieces",
            amount: null,
          },
          {
            date: "15/12/2022",
            status: "Partially picked up from Daniel Roy",
            cashier: "Cashier 03",
            details: "290 pieces | $430.90 for picked items",
            amount: 430.9,
          },
          {
            date: "25/12/2022",
            status: "Partially picked up from Anns Marie",
            cashier: "Cashier 01",
            details: "255 pieces | $512.50 for picked items",
            amount: 512.5,
          },
          {
            date: "25/12/2022",
            status: "Bill Edited | Anns Marie",
            cashier: "Cashier 01",
            details: "$943.00 total payable",
            amount: 943.0,
          },
          {
            date: "26/12/2022",
            status: "Paid total bill amount | Daniel Roy",
            cashier: "Cashier 03",
            details: "$943.00 | Paid by John Doe",
            amount: 943.0,
          },
          {
            date: "26/12/2022",
            status: "Bill Closed by Daniel Roy",
            cashier: "Cashier 03",
            details: "Closed by Cashier",
            amount: null,
          },
          {
            date: "26/12/2022",
            status: "Bill Closed by Daniel Roy",
            cashier: "Cashier 03",
            details: "Closed by Cashier",
            amount: null,
          },
          {
            date: "26/12/2022",
            status: "Bill Closed by Daniel Roy",
            cashier: "Cashier 03",
            details: "Closed by Cashier",
            amount: null,
          },
          {
            date: "26/12/2022",
            status: "Bill Closed by Daniel Roy",
            cashier: "Cashier 03",
            details: "Closed by Cashier",
            amount: null,
          },
          {
            date: "26/12/2022",
            status: "Bill Closed by Daniel Roy",
            cashier: "Cashier 03",
            details: "Closed by Cashier",
            amount: null,
          },
          {
            date: "26/12/2022",
            status: "Bill Closed by Daniel Roy",
            cashier: "Cashier 03",
            details: "Closed by Cashier",
            amount: null,
          },
        ],
      });
    }
  }, [billId]);

  const timelineItems = clickedRow
    ? clickedRow.history.map((item: any) => ({
        title: (
          <Typography>
            <span style={{ color: Colors.MAIN_GRAY, fontWeight: "bold" }}>
              {item.date || "N/A"}
            </span>
            {" - "}
            <span style={{ color: Colors.MAIN_GRAY }}>
              {item.time || "00:00"}
            </span>
          </Typography>
        ),
        cardTitle: (
          <Typography sx={{ color: Colors.MAIN_GRAY, fontWeight: "bold" }}>
            {`${item.status || "Status Unknown"} | ${
              item.cashier || "Cashier Unknown"
            }`}
          </Typography>
        ),
        cardSubtitle: (
          <Typography sx={{ color: Colors.MAIN_GRAY }}>
            {item.details || "No details available"}
          </Typography>
        ),
        cardDetailedText:
          item.amount !== null ? (
            <Typography sx={{ color: Colors.MAIN_GRAY }}>
              {`$${item.amount} - ${item.details}`}
            </Typography>
          ) : (
            <Typography sx={{ color: Colors.MAIN_GRAY }}>
              {item.details}
            </Typography>
          ),
      }))
    : [];

  return (
    <CustomModal
      title=""
      openModal={open}
      onClose={onClose}
      width="51.7857142857rem"
      height="70.8571428571rem"
    >
      <Box
        sx={{
          p: 4,
          position: "relative",
          width: "100%",
          height: "100%",
          borderRadius: "0.71rem",
          boxShadow: "none",
        }}
      >

        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Box
            component="button"
            sx={{
              bgcolor: Colors.SECONDARY,
              color: Colors.WHITE,
              width: "11.06rem",
              height: "2.95rem",
              fontSize: "1.4286rem",
              borderRadius: "0.71rem",
              padding: "0.5rem 1rem",
              border: "none",
              cursor: "pointer",
              marginTop: "-10rem",
              marginLeft: "-3rem"
            }}
          >
            {`IN${billId}`}
          </Box>
        </Box>

        <Box sx={{ height: "60rem", width: "130%", marginLeft: "-6rem", marginTop: "-1rem" }}>
          {timelineItems.length > 0 ? (
            <Chrono
              items={timelineItems}
              mode="VERTICAL"
              cardHeight={pxToRem(108.5)}
              theme={{
                primary: Colors.SECONDARY,
                secondary: Colors.WHITE,
                cardBgColor: Colors.WHITE,
              }}
              disableToolbar
            />
          ) : (
            <Typography>No history available</Typography>
          )}
        </Box>
      </Box>
    </CustomModal>
  );
};

export default InvoiceHistoryModal;
