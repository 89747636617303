import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";

import { CustomButton, CustomTextBox } from "../../../atoms";
import CustomModal from "../../custom-modal/custom-modal";
import { Colors } from "../../../../configs";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { IDamage } from "../../../../typings/interfaces/pos/cart-item";

export interface IDamagePromptModalProps {
  onClose(): void;
  promptOpen: boolean;
  onSubmit(damage: IDamage[]): void;
}

const DamagePromptModal: FC<IDamagePromptModalProps> = ({
  promptOpen,
  onClose,
  onSubmit,
}) => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const isAlreadyAdded: boolean =
    selectedCartItem?.damages.some((i) => i.id === 100000) || false;
  const [damage, setDamage] = useState<string>(
    selectedCartItem?.damages.filter((i) => i.id === 100000)[0]?.label ?? ""
  );

  const handleAddDamage = () => {
    onSubmit([
      ...(selectedCartItem?.damages || []),
      { id: 100000, label: damage },
    ]);
  };

  const handleRemoveDamage = () => {
    onSubmit(selectedCartItem?.damages.filter((i) => i.id !== 100000) || []);
    onClose();
  };

  const handleUpdateDamage = () => {
    onSubmit(
      selectedCartItem?.damages.map((i) =>
        i.id === 100000 ? { id: 100000, label: damage } : i
      ) || []
    );
  };

  const handleOnSubmit = () => {
    if (isAlreadyAdded) {
      handleUpdateDamage();
    } else {
      handleAddDamage();
    }
    onClose();
  };

  return (
    <CustomModal
      mode="popup"
      title=""
      openModal={promptOpen}
      onClose={onClose}
      width="40rem"
      height="25rem"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", py: "0.6rem"  }}>
        <Typography sx={{ fontSize: "1.5rem" }}>
          Please provide any specific damages for this item.
        </Typography>
        <CustomTextBox
          placeholder="Enter Your Damage"
          value={damage}
          onChange={(e) => setDamage(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            gap: "0.7rem",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "12rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              background: "none",
              border: `0.11429rem solid ${Colors.MAIN_GRAY}`,
              color: Colors.MAIN_GRAY,
              fontWeight: 600,
            }}
            disabled={!isAlreadyAdded}
            onClick={handleRemoveDamage}
          >
            Remove
          </CustomButton>
          <CustomButton
            sx={{
              height: "4.5rem",
              width: "13.5rem",
              borderRadius: "0.71429rem",
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
            disabled={damage === ""}
            onClick={handleOnSubmit}
          >
            {isAlreadyAdded ? "Update" : "Add"}
          </CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default DamagePromptModal;
