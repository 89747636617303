import { FC } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";

import { AdminLayout } from "../../../../components/layouts/admin-layout/admin-layout";
import { Colors } from "../../../../../src/configs/colors";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

interface CashierPoint {
  storeName: string;
  cashierPointName: string;
  ipAddress: string;
  machine: string;
  browser: string;
}

const sampleData: CashierPoint[] = [
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
  {
    storeName: "Store 01",
    cashierPointName: "Cashier Point 01",
    ipAddress: "192.168.1.38",
    machine: "Machine 01",
    browser: "Browser",
  },
];

const tableHeaderCellStyles = {
  fontWeight: "bold",
  color: Colors.MAIN_GRAY,
  height: "3.428571428571429rem",
  lineHeight: "3.428571428571429rem",
};

const tableCellStyles = {
  fontSize: "1rem",
  color: Colors.MAIN_GRAY,
  height: "3.428571428571429rem",
  lineHeight: "3.428571428571429rem",
};

export const CashierPointsPage: FC = () => {
  const snackbar = useSnackbarContext();

  return (
    <AdminLayout>
      <Box>
        <Typography
          sx={{
            color: Colors.MAIN_GRAY,
            fontSize: "1.714285714285714rem",
            fontWeight: "bold",
            marginTop: "3rem",
          }}
        >
          Requests for Cashier Points
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "55rem",
            marginTop: "3.5rem",
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "0.5rem" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADADAD",
              borderRadius: "0rem",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "0rem",
            },
          }}
        >
          <Table aria-label="cashier points table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: Colors.WHITE,
                  height: "3.428571428571429rem",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                }}
              >
                {[
                  "Store Name",
                  "Cashier Point Name",
                  "IP Address",
                  "Machine",
                  "Browser",
                  "Action",
                ].map((header, index) => (
                  <TableCell
                    key={index}
                    sx={tableHeaderCellStyles}
                    align={header === "Action" ? "center" : "left"}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sampleData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: Colors.BACKSHADE_GRAY,
                    },
                    height: "3.428571428571429rem",
                  }}
                >
                  {[
                    row.storeName,
                    row.cashierPointName,
                    row.ipAddress,
                    row.machine,
                    row.browser,
                  ].map((cell, i) => (
                    <TableCell key={i} sx={tableCellStyles}>
                      {cell}
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    <IconButton color="error">
                      <Cancel sx={{ fontSize: "1.714285714285714rem" }} />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={() =>
                        snackbar.showSnackbar("Action successful!", "success")
                      }
                    >
                      <CheckCircle sx={{ fontSize: "1.714285714285714rem" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </AdminLayout>
  );
};
