import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  BaseResponse,
  IcustomerServiceUsageResponse,
} from "../../typings/interfaces/responses";
import {
  GET_CUSTOMER_ALL_TIME_SERVICE_USAGE,
  GET_CUSTOMER_CURRENT_YEAR_SERVICE_USAGE,
} from "../../configs/apiConfig";

export const getAllCustomerCurrentYearServiceUsageAction = createAsyncThunk(
  "customer/customerCurrentYearServiceUsage",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<IcustomerServiceUsageResponse>
      >(GET_CUSTOMER_CURRENT_YEAR_SERVICE_USAGE(id));
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllCustomerAllTimeServiceUsageAction = createAsyncThunk(
  "customer/customerAllTimeServiceUsage",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get<
        BaseResponse<IcustomerServiceUsageResponse>
      >(GET_CUSTOMER_ALL_TIME_SERVICE_USAGE(id));
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
