import { FC, useEffect, useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

import { Colors } from "../../../configs";

export interface ICustomModalProps {
  title: string;
  onClose?(): void;
  openModal: boolean;
  children?: React.ReactNode;
  width?: string;
  height?: string;
  mode?: "modal" | "popup";
  backdropProps?: any;
  disableBackdropClick?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  py: "3.49321429rem",    // 3.99321429rem
  px: "3.57142857rem",   // 5.57142857rem
  borderRadius: "0.3571428571428571rem", 
};

const CustomModal: FC<ICustomModalProps> = ({
  title,
  onClose,
  openModal,
  children,
  width = "30%",
  height = "40%",
  mode = "modal",
  backdropProps = {
    sx: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  },
  disableBackdropClick = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick"
  ) => {
    if (
      !(
        disableBackdropClick &&
        (reason === "backdropClick" || reason === "escapeKeyDown")
      )
    ) {
      setOpen(false);
      onClose && onClose();
    }
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999 }}
      hideBackdrop={false}
      slotProps={{
        backdrop: backdropProps,
      }}
    >
      <Box sx={{ ...style, width, height }}>
        <Box
          sx={{
            display: mode === "popup" ? "none" : "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ fontWeight: "bold", color: Colors.TEXT_GRAY_DARK }}
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            size="large"
            onClick={() => handleClose({}, "closeButtonClick")}
            sx={{ alignSelf: "flex-start", width: "2rem", height: "2rem" }}
          >
            <HighlightOff sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
