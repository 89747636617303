export const CardIcon = ({ color = "#515151" }) => {
  return (
    <svg
      width="30"    //previously 41
      height="30"    //previously 32
      viewBox="0 0 41 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 28.5714C0 30.4643 1.53038 32 3.41667 32H37.5833C39.4696 32 41 30.4643 41 28.5714V16H0V28.5714ZM13.6667 23.7143C13.6667 23.2429 14.051 22.8571 14.5208 22.8571H24.2014C24.6712 22.8571 25.0556 23.2429 25.0556 23.7143V26.5714C25.0556 27.0429 24.6712 27.4286 24.2014 27.4286H14.5208C14.051 27.4286 13.6667 27.0429 13.6667 26.5714V23.7143ZM4.55556 23.7143C4.55556 23.2429 4.93993 22.8571 5.40972 22.8571H10.5347C11.0045 22.8571 11.3889 23.2429 11.3889 23.7143V26.5714C11.3889 27.0429 11.0045 27.4286 10.5347 27.4286H5.40972C4.93993 27.4286 4.55556 27.0429 4.55556 26.5714V23.7143ZM41 3.42857V6.85714H0V3.42857C0 1.53571 1.53038 0 3.41667 0H37.5833C39.4696 0 41 1.53571 41 3.42857Z"
        fill={color}
      />
    </svg>
  );
};
