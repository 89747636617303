import React, { FC, useState } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ReprintModal } from "../../../components/pos/reprint-modal/reprint-modal";
import { Colors } from "../../../configs";

interface IPintagTableRowProps {
  firstRow: boolean;
  lastRow: boolean;
  isSelected: boolean;
  onClick: () => void;
  onViewClick: () => void;
}

const PintagTableRow: FC<IPintagTableRowProps> = ({
  firstRow,
  lastRow,
  onClick,
  onViewClick,
  isSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reprintType, setReprintType] = useState<"invoice" | "pintag">(
    "invoice"
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement | SVGElement>
  ) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openReprintModal = (type: "invoice" | "pintag") => {
    setReprintType(type);
    setOpenModal(true);
    handleMenuClose();
  };

  const closeReprintModal = () => {
    setOpenModal(false);
  };

  const styles = {
    tableBodyCell: {
      background: isSelected ? Colors.BROWN : Colors.ACCENT_GRAY,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1rem",
      color: isSelected ? "#ffffff" : "#000000",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.15rem",
        flexWrap: "nowrap",
        marginBottom: "0.15rem",
        height: "2.92857142857rem",
      }}
    >
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          ...(lastRow ? { borderBottomLeftRadius: "0.71rem" } : {}),
          flex: 1,
          minWidth: "2.92857142857rem",
          maxWidth: "2.92857142857rem !important",
          width: "3.246rem !important",
          boxSizing: "border-box !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Box
          sx={{
            width: "1.42857142857rem",
            height: "1.42857142857rem",
            backgroundColor: Colors.PINTAG5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Box>

      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "6.606rem",
          maxWidth: "6.606rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>pintag</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "6.601rem",
          maxWidth: "6.601rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>invoice</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "15.601rem",
          maxWidth: "15.601rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>customer</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "9.821rem",
          maxWidth: "9.821rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>DD/MM/YY</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "12.601rem",
          maxWidth: "12.601rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>pickup cashier</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "12.601rem",
          maxWidth: "12.601rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>drop cashier</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "9.801rem",
          maxWidth: "9.801rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>DD/MM/YY</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "7.601rem",
          maxWidth: "7.601rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>peices</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "7.601rem",
          maxWidth: "7.601rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>rack</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 4,
          minWidth: "9.801rem",
          maxWidth: "9.801rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>DD/MM/YY</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          margin: "0.01rem",
          flex: 2.5,
          minWidth: "20.801rem",
          maxWidth: "20.801rem !important",
          padding: "0.5rem 0.71rem",
        }}
      >
        <Typography>descriptions</Typography>
      </Box>
      <Box
        sx={{
          ...styles.tableBodyCell,
          fontSize: "1rem",
          margin: "0.01rem",
          ...(lastRow ? { borderBottomRightRadius: "0.71rem" } : {}),
          flex: 1.5,
          minWidth: "2.92857142857rem",
          maxWidth: "2.92857142857rem !important",
          padding: "0.5rem 0.71rem",
        }}
        onClick={onViewClick}
      >
        <MoreVertIcon
          sx={{ cursor: "pointer", color: "inherit", fontSize: "1.5rem" }}
          onClick={(event) => handleMenuClick(event)}
        />

        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => openReprintModal("invoice")}>
            Reprint Invoice
          </MenuItem>
          <MenuItem onClick={() => openReprintModal("pintag")}>
            Reprint Tag
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
          >
            View Bill
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
          >
            Update Pintag
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
          >
            Pickup Screens
          </MenuItem>
        </Menu>
      </Box>

      {/* ReprintModal */}
      {openModal && (
        <ReprintModal
          open={openModal}
          selectedTab={reprintType}
          onClose={closeReprintModal}
        />
      )}
    </Box>
  );
};

export default PintagTableRow;
