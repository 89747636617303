export const CheckIcon = ({ color = "#515151" }) => {
  return (
    <svg
      width="32"    //previously 45
      height="30"    //previously 31
      viewBox="0 0 45 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 28.7857C0 30.0084 1.00758 31 2.25 31H42.75C43.9924 31 45 30.0084 45 28.7857V6.64286H0V28.7857ZM31.5 14.3929C31.5 13.7812 32.0034 13.2857 32.625 13.2857H39.375C39.9966 13.2857 40.5 13.7812 40.5 14.3929V16.6071C40.5 17.2188 39.9966 17.7143 39.375 17.7143H32.625C32.0034 17.7143 31.5 17.2188 31.5 16.6071V14.3929ZM31.5 22.6964C31.5 22.3906 31.7517 22.1429 32.0625 22.1429H39.9375C40.2483 22.1429 40.5 22.3906 40.5 22.6964V23.8036C40.5 24.1094 40.2483 24.3571 39.9375 24.3571H32.0625C31.7517 24.3571 31.5 24.1094 31.5 23.8036V22.6964ZM4.5 16.0536C4.5 15.7477 4.75172 15.5 5.0625 15.5H26.4375C26.7483 15.5 27 15.7477 27 16.0536V17.1607C27 17.4666 26.7483 17.7143 26.4375 17.7143H5.0625C4.75172 17.7143 4.5 17.4666 4.5 17.1607V16.0536ZM4.5 22.6964C4.5 22.3906 4.75172 22.1429 5.0625 22.1429H17.4375C17.7483 22.1429 18 22.3906 18 22.6964V23.8036C18 24.1094 17.7483 24.3571 17.4375 24.3571H5.0625C4.75172 24.3571 4.5 24.1094 4.5 23.8036V22.6964ZM43.875 0H1.125C0.503438 0 0 0.495446 0 1.10714V4.42857H45V1.10714C45 0.495446 44.4966 0 43.875 0Z"
        fill={color}
      />
    </svg>
  );
};
