import { FC, useState } from "react";
import { Box, Button } from "@mui/material";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { Colors } from "../../../configs";

interface IReprintModalProps {
  open: boolean;
  onClose: () => void;
  selectedTab?: "invoice" | "pintag";
}

export const ReprintModal: FC<IReprintModalProps> = ({
  open,
  onClose,
  selectedTab = "invoice",
}) => {
  const [activeType, setActiveType] = useState<"invoice" | "pintag">(
    selectedTab
  );

  return (
    <CustomModal
      title="Reprint"
      openModal={open}
      onClose={onClose}
      width="42.48571428571429rem"
      height="32.92857142857143rem"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", marginTop: "0.5rem", gap: 0 }}>
          <Button
            onClick={() => setActiveType("invoice")}
            variant="contained"
            sx={{
              height: "2.214285714285714rem",
              width: "7.428571428571429rem",
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "bold",
              color: activeType === "invoice" ? Colors.WHITE : Colors.PRIMARY,
              bgcolor:
                activeType === "invoice"
                  ? Colors.PRIMARY
                  : Colors.BACKSHADE_GRAY,
              borderRadius: "0.71rem 0 0 0",
              boxShadow: "none",
              "&:hover": {
                bgcolor:
                  activeType === "invoice"
                    ? Colors.PRIMARY
                    : Colors.BACKSHADE_GRAY,
                boxShadow: "none",
              },
            }}
          >
            Invoice
          </Button>
          <Button
            onClick={() => setActiveType("pintag")}
            variant="contained"
            sx={{
              height: "2.214285714285714rem",
              width: "7.428571428571429rem",
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "bold",
              color: activeType === "pintag" ? Colors.WHITE : Colors.PRIMARY,
              bgcolor:
                activeType === "pintag"
                  ? Colors.PRIMARY
                  : Colors.BACKSHADE_GRAY,
              borderRadius: "0 0.71rem 0 0",
              boxShadow: "none",
              "&:hover": {
                bgcolor:
                  activeType === "pintag"
                    ? Colors.PRIMARY
                    : Colors.BACKSHADE_GRAY,
                boxShadow: "none",
              },
            }}
          >
            Pintag
          </Button>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "0.2rem",
            backgroundColor: Colors.BACKSHADE_GRAY,
            marginTop: "-2.7rem",
          }}
        />

        <Box
          sx={{
            display: "flex",
            gap: 1,
            marginTop: "-0.4rem",
            marginLeft: "1rem",
          }}
        >
          {activeType === "invoice" ? (
            <>
              <Button
                variant="outlined"
                sx={{
                  width: "14.14285714285714rem",
                  height: "8.428571428571429rem",
                  fontSize: "1.428571428571429rem",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: Colors.PRIMARY,
                  bgcolor: Colors.BACKGROUND_GRAY,
                  border: "none",
                  borderRadius: "0.71rem",
                }}
              >
                Reprint Custom Copy
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "14.14285714285714rem",
                  height: "8.428571428571429rem",
                  fontSize: "1.428571428571429rem",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: Colors.PRIMARY,
                  bgcolor: Colors.BACKGROUND_GRAY,
                  border: "none",
                  borderRadius: "0.71rem",
                }}
              >
                Reprint Store Copy
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  width: "14.14285714285714rem",
                  height: "8.428571428571429rem",
                  fontSize: "1.428571428571429rem",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: Colors.PRIMARY,
                  bgcolor: Colors.BACKGROUND_GRAY,
                  borderRadius: "0.71rem",
                }}
              >
                Reprint Single Tag
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "14.14285714285714rem",
                  height: "8.428571428571429rem",
                  fontSize: "1.428571428571429rem",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: Colors.PRIMARY,
                  bgcolor: Colors.BACKGROUND_GRAY,
                  border: "none",
                  borderRadius: "0.71rem",
                }}
              >
                Reprint All Tags
              </Button>
            </>
          )}
        </Box>

        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            marginTop: "-0.8rem",
            width: "100%",
            fontSize: "1.714285714285714rem",
            fontWeight: "bold",
            height: "4.571428571428571rem",
            textTransform: "none",
            color: Colors.WHITE,
            bgcolor: Colors.PRIMARY,
            borderRadius: "0.71rem",
            "&:hover": { bgcolor: Colors.PRIMARY },
          }}
        >
          Done
        </Button>
      </Box>
    </CustomModal>
  );
};
